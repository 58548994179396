import { useState, memo, Fragment, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toPng } from 'html-to-image'
import { getPercent, numberFormat } from 'utils'
import { changeOrder, ppts, getSummaryTotal, getSection, componentType } from 'utils/analysis'
import { withSurveyPopoverImage } from 'hoc'
import indicator from 'ordinal/indicator'
import { useIntl } from 'react-intl'
import { isEqual } from 'lodash-es'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import {  
    QuestionNumberStyled,
    QuestionBoxStyled,
    QuestionBoxForMakeImageStyled,
    WrapperBarStyled,
    WrapperBarRankStyled,
    ImgStyled
} from 'styled/Survey/Analysis/Summary'
import Section from 'styled/Survey/Analysis/SummarySection'
import NpsLabel from 'styled/Survey/Analysis/SummaryNpsLabel'
import NpsCalcu from 'styled/Survey/Analysis/SummaryNpsCalcu'
import SummaryQuestion from 'styled/Survey/Analysis/SummaryQuestion'
import SummaryQuestionReport from 'styled/Survey/Analysis/SummaryQuestionReport'
import BarStyled from 'styled/Survey/Analysis/SummaryBar'
import BarHoriStyled from 'styled/Survey/Analysis/SummaryBarHori'
import WrapperBarMatrixStyled from 'styled/Survey/Analysis/SummaryWrapperBarMatrix'
import WrapperBarImgMatrixStyled from 'styled/Survey/Analysis/SummaryWrapperBarImgMatrix'
import WrapperBarPointStyled from 'styled/Survey/Analysis/SummaryWrapperBarPoint'
import WrapperBarPointHoriStyled from 'styled/Survey/Analysis/SummaryWrapperBarPointHori'
import QuestionPaper from 'styled/Survey/Analysis/SummaryQuestionPaper'
import CommentsStyled from 'styled/Survey/Analysis/SummaryComment'
import CommentsButtonStyled from 'styled/Survey/Analysis/SummaryCommentButton'
import CommentsItemStyled from 'styled/Survey/Analysis/SummaryCommentItem'
import DataItemFileUploadComponent from './DataItemFileUpload'
import DataItemMoreComponent from './DataItemMore'
import NumberIconComponent from './NumberIcon'
import VisibleLangComponent from './VisibleLang'
import SurveyCreateAnalysisSummaryQuestionButtonsComponent from './QuestionButtons'
import QuestionButtonModuleTextComponent from './QuestionButtonModuleText'
import QuestionButtonRankTextComponent from './QuestionButtonRankText'
import QuestionButtonTextComponent from './QuestionButtonText'
import QuestionButtonPptComponent from './QuestionButtonPpt'
import QuestionButtonFileComponent from './QuestionButtonFile'
import QuestionButtonImageComponent from './QuestionButtonImage'
import DataItemCommentsComponent from './DataItemComments'
import DataItemRankCommentsComponent from './DataItemRankComments'
import DataItemCommentHeaderComponent from './DataItemCommentHeader'

const MyDivider = withStyles({
    root: (props) => {
        const { last } = props

        return {
            marginTop: 15,
            marginBottom: last ? 0 : 15
        }
    } 
})(props => <Divider {...props} light/>)

const CollapseBox = withStyles({
    root: (props) => {
        const { open, print } = props

        const style = open ? { } : { height: 0 } 

        return {
            ...style,
            transition: 'all .6s',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    }
})(Box)

const BarComponent = (props) =>  {
    const { index, total, cc, ccText=[], answer } = props

    const per = getPercent(cc, total)
    const cols = [...ccText].join('   |  ')

    return (
        <WrapperBarStyled>
            <BarStyled index={index} per={per} answer={answer} cols={cols}/>
        </WrapperBarStyled>
    )
}

const BarHoriComponent = (props) =>  {
    const { total, cc, answer, ccText=[] } = props

    const per = getPercent(cc, total)
    const cols = [...ccText].join('<br/>')

    return (
        <WrapperBarStyled>
            <BarHoriStyled answer={answer} per={per} cols={cols}/>
        </WrapperBarStyled>
    )
}

const BarRankComponent = (props) =>  {
    const { total, cc, ccText=[], number=1, answer, color='', isRank=true } = props

    return (
        <WrapperBarPointHoriStyled conts={isRank ? `${number}${indicator(number)}` : number}>
            <BarHoriComponent total={total} cc={cc} ccText={ccText} answer={answer} color={color}/>
        </WrapperBarPointHoriStyled>
    )
}

const BarMatrixColumnComponent = (props) => {
    const { answer, children } = props

    return (
        <WrapperBarMatrixStyled answer={answer}>
            {children}
        </WrapperBarMatrixStyled>
    )
}


const BarImgMatrixColumnComponent = (props) => {
    const { answer, src, children } = props

    return (
        <WrapperBarImgMatrixStyled answer={answer} src={src}>
            {children}
        </WrapperBarImgMatrixStyled>
    )
}

const BarImageComponent = (props) => {
    const { total, cc, ccText, src, answer, color, animation, onOpen } = props
 
    return (
        <WrapperBarPointStyled conts={<ImgStyled src={src} onClick={() => onOpen(src)}/>}>
            <BarComponent total={total} cc={cc} ccText={ccText} answer={answer} color={color} animation={animation}/>
        </WrapperBarPointStyled>
    )
}

export const Bar01Component = (props) => {
    const { modules, order, animation } = props

    const total = getSummaryTotal.count(modules)

    changeOrder.count({ data: modules, order })

    return modules.map((c, i) => <BarComponent key={c.survey_module_no} index={i} total={total} cc={c.count} ccText={[`${numberFormat(c.count)}`]} answer={c.answer} animation={animation}/>)
}

 const Bar03ColumnComponent = (props) => {
    const { modules, order, animation } = props

    return modules.map(c => {

        const total = getSummaryTotal.count(c.answers)
        changeOrder.count({ data: c.answers, order })

        return (
            <BarMatrixColumnComponent key={c.survey_module_no} answer={c.column}>
                {
                    c.answers.map((d, i) => (
                        <BarComponent 
                            key={d.survey_module_answer_no} 
                            index={i} 
                            total={total} 
                            cc={d.count} 
                            ccText={[`${numberFormat(d.count)}`]} 
                            answer={d.answer} 
                            animation={animation}
                        />
                    ))
                }
            </BarMatrixColumnComponent>
        )
    })
}

const Bar03AnswerComponent = (props) => {
    const { answers, order, animation } = props

    const total = getSummaryTotal.count(answers)
    changeOrder.count({ data: answers, order })

    return (
        <>
            {
                answers.map((c, i) => <BarComponent key={c.survey_module_answer_no} index={i} total={total} cc={c.count} ccText={[numberFormat(c.count)]} answer={c.answer} animation={animation} color="pink"/>)
            }
        </>
        
    )
}

const Bar05Component = (props) => {
    const { modules, animation  } = props
    const total = getSummaryTotal['count'](modules)

    return (

            <WrapperBarRankStyled>
            {
                modules.map((c, i) => {
                    const cc = c['count']
                    const ccText = numberFormat(cc)
                    const number = c.indexs
            
                    return (
                        
                        <BarRankComponent 
                            key={c.survey_module_no} 
                            total={total} 
                            cc={cc} 
                            ccText={[ccText]} 
                            number={number} 
                            animation={animation} 
                            isRank={false}
                        />
                    )
                })    
            }
            </WrapperBarRankStyled>
  
    )
}

const Bar07Component = (props) => {
    const { modules, animation  } = props

    const total = getSummaryTotal['count'](modules) 

    return (
        <WrapperBarRankStyled>
            {
                modules.map(c => {
                    const cc = c['count']
                    const ccText = numberFormat(cc)
                    const number = c.indexs
                    const answer = c.answer

                    return (
                        <BarRankComponent 
                            key={c.survey_module_no} 
                            total={total} 
                            cc={cc} 
                            ccText={[ccText]} 
                            number={number} 
                            answer={answer} 
                            animation={animation} 
                            isRank={false}
                        />
                    )
                })    
            }
        </WrapperBarRankStyled>
    )
}

const Bar06ColumnComponent = (props) => {
    const { modules, option, animation } = props

    return modules.map(c => {

        const total = getSummaryTotal['count'](c.answers) 

        return (
            <BarMatrixColumnComponent key={c.survey_module_no} answer={c.column}>
                <NpsCalcu rows={c.answers} isNps={option.nps}/>
                <WrapperBarRankStyled>
                {
                    c.answers.map(d => {
                        const cc = d['count']
                        const ccText = numberFormat(cc)
                        const number = d.indexs
                     

                        return (
                            <BarRankComponent 
                                key={d.survey_module_answer_no} 
                                total={total} 
                                cc={cc} 
                                ccText={[ccText]} 
                                number={number} 
                                answer=""
                                animation={animation} 
                                isRank={false}
                            />
                        )
                    })
                }
                </WrapperBarRankStyled>
                <NpsLabel isNps={option.nps} left_label={option.left_label} right_label={option.right_label}/>
            </BarMatrixColumnComponent>
        )
    })
}

const Bar06AnswerComponent = (props) => {
    const { answers, animation } = props

    const total = getSummaryTotal['count'](answers)

    return (
       <WrapperBarRankStyled>
        {
            answers.map((c, i) => {
                const cc = c['count']
                const ccText = numberFormat(cc)
                const number = c.indexs
        
                return (
                    <BarRankComponent 
                        key={c.survey_module_answer_no} 
                        total={total} 
                        cc={cc} 
                        ccText={[ccText]} 
                        number={number} 
                        answer=""
                        animation={animation} 
                        isRank={false}
                    />
                )
            })
        }
       </WrapperBarRankStyled>
        
    )
}

const Bar08ColumnComponent = (props) => {
    const { modules, animation } = props

    return modules.map(c => {

        const total = getSummaryTotal['count'](c.answers) 
     
        return (
            <BarMatrixColumnComponent key={c.survey_module_no} answer={c.column}>
                <NpsCalcu rows={c.answers} isNps={false}/>
                <WrapperBarRankStyled>
                {
                    c.answers.map(d => {
                        const cc = d['count']
                        const ccText = numberFormat(cc)
                        const number = d.indexs
                        const answer = d.answer

                        return (
                            <BarRankComponent 
                                key={d.survey_module_answer_no} 
                                total={total} 
                                cc={cc} 
                                ccText={[ccText]} 
                                number={number} 
                                answer={answer} 
                                animation={animation} 
                                isRank={false}
                            />
                        )
                    })
                }
                </WrapperBarRankStyled>
            </BarMatrixColumnComponent>
        )
    })
}

const Bar08AnswerComponent = (props) => {
    const { answers, animation } = props

    const total = getSummaryTotal['count'](answers)

    return (
        <WrapperBarRankStyled>
            {
                answers.map(c => {
                    const cc = c['count']
                    const ccText = numberFormat(cc)
                    const number = c.indexs
                    const answer = c.answer

                    return (
                        <BarRankComponent 
                            key={c.survey_module_answer_no} 
                            total={total} 
                            cc={cc} 
                            ccText={[ccText]} 
                            number={number} 
                            answer={answer} 
                            animation={animation} 
                            isRank={false}
                        />
                    )
                })
            }
        </WrapperBarRankStyled>
    )
}

 const Bar11Component = (props) => {
    const { modules, order, animation  } = props

    const total = getSummaryTotal.point(modules) 
    changeOrder.point({ data: modules, order })

    return (
        <>
            {
                modules.map((c, i) => {
                    const cc = c.point
                    const ccText = [`${numberFormat(cc)}P`, c.deviation.toFixed(1)]
                    const answer = c.answer

                    return <BarComponent key={c.survey_module_answer_no} index={i} total={total} cc={cc} ccText={ccText} answer={answer} animation={animation}/>
                })
            }
        </>
    )  
}

 const Bar11AnswerComponent = (props) => {
    const { answers, order, animation } = props

    const len = answers.length

    return answers.map((c, i) => {
        const total = getSummaryTotal.count(c.ranks) 
        changeOrder.count({ data: c.ranks, order })

        return (
            <Fragment key={c.survey_module_answer_no} >
                <BarMatrixColumnComponent answer={c.answer}>
                    <WrapperBarRankStyled>
                    {
                        c.ranks.map(d => {
                            const cc = d.count
                            const ccText = numberFormat(cc)
                            const number = d.indexs
                            const answer = d.answer

                            return <BarRankComponent key={d.survey_module_no} total={total} cc={cc} ccText={[ccText]} number={number} answer={answer} animation={animation} color="pink"/>
                        })
                    }
                    </WrapperBarRankStyled>
                </BarMatrixColumnComponent>
                <MyDivider last={i === (len-1) ? 1 : 0}/>
            </Fragment>
        )
    })
}

 const Bar12Component = (props) => {
    const { modules, order, animation, onOpen } = props

    const total = getSummaryTotal.count(modules)

    changeOrder.count({ data: modules, order })

    return modules.map(c => <BarImageComponent key={c.survey_module_no} src={c.src} total={total} cc={c.count} ccText={[numberFormat(c.count)]} answer={c.answer} animation={animation} onOpen={onOpen}/>)
}

const Bar18Component = (props) => {
    const { modules, order, animation, onOpen } = props

    const total = getSummaryTotal.point(modules) 
    changeOrder.point({ data: modules, order })

    return (
        <>
            {
                modules.map((c, i) => {
                    const cc = c.point
                    const ccText = [`${numberFormat(cc)}P`, c.deviation.toFixed(1)]
                    const answer = c.answer
                    const src = c.src

                    return (
                 
                    <BarImageComponent key={c.survey_module_answer_no} src={src} total={total} cc={cc} ccText={ccText} answer={answer} animation={animation} onOpen={onOpen}/>
                    )
                })
            }
        </>
    )  
}

const Bar18AnswerComponent = (props) => {
    const { answers, order, animation } = props

    const len = answers.length

    return answers.map((c, i) => {
        const total = getSummaryTotal.count(c.ranks) 
        changeOrder.count({ data: c.ranks, order })

        return (
            <Fragment key={c.survey_module_answer_no} >
                <BarImgMatrixColumnComponent answer={c.answer} src={c.src}>
                    <WrapperBarRankStyled>
                    {
                        c.ranks.map(d => {
                            const cc = d.count
                            const ccText = numberFormat(cc)
                            const number = d.indexs
                            const answer = d.answer

                            return <BarRankComponent key={d.survey_module_no} total={total} cc={cc} ccText={[ccText]} number={number} answer={answer} animation={animation} color="pink"/>
                        })
                    }
                    </WrapperBarRankStyled>
                </BarImgMatrixColumnComponent>
                <MyDivider last={i === (len-1) ? 1 : 0}/>
            </Fragment>
        )
    })
}


export const CommentsComponent = props => {
    const { comments, orderComment, className='' } = props

    changeOrder.comment({ data: comments, order: orderComment })

    let len = comments.length
    if (len > 5) len = 5

    const arr = []

    for (let i=0; i<len; i++) {
        arr[i] = comments[i]
    }

    return (
        <CommentsStyled className={className} length={comments.length}>
            {
                arr.map((d, j) => <CommentsItemStyled key={d.idd} idd={d.idd} comment={d.comment} wdate={d.wdate}/>)
            }
        </CommentsStyled>
    )
}

// 선택형 보기별 코멘트
const CommentsModulesComponent = (props) => {
    const { survey_no, survey_question_no, modules } = props

    return modules.filter(c => c.commentLen > 0).map((c, i) => {
        return (
            <Fragment   key={c.survey_module_no} >
                <DataItemCommentHeaderComponent index={i} answer={c.answer} commentLen={c.commentLen}/>
                <DataItemCommentsComponent
                    survey_no={survey_no} 
                    id={`${survey_question_no}-${c.survey_module_no}`} 
                    comments={c.comments} 
                    commentLen={c.commentLen}
                /> 
            </Fragment>
           
        )
    })
}

// 그래프를 이미지로 만든다.
export const saveGraphImage = (props) => {
    return new Promise((resolve, reject) => {
        const { ref, name } = props

        // ref에 패딩 10 주기
        ref.current.style.setProperty('padding', '10px')
        // 더보기 element
        const collapseElement = ref.current.querySelector('div.collapse')
        // 내용 속 비디오 element
        const videoElement =  ref.current.querySelector('div.video')
        // 비디오 내용을 담을 임시 일레먼트
        const videoTmpElement = window.document.createElement('div')
        videoTmpElement.style = 'display: none'
        
        // 더보기 그래프는 스크롤이 생길 수 있으므로 최대 높이값으로 임의로 맞춘다.
        if (collapseElement) {
            if (collapseElement.clientHeight > 0) {
                ref.current.classList.add('make-image-collapse')
                // 다운로드시 more 버튼 안보이게 처리
                ref.current.classList.add('make-image-more-opacity')
            } else ref.current.classList.add('make-image-more-none')
        } else ref.current.classList.add('make-image-more-none')
        
        // 비디오 영상은 이미지다운로드할때 에러나므로 임시 div에 저장해 둔다
        if (videoElement) {
            videoTmpElement.innerHTML = videoElement.innerHTML
            videoElement.innerHTML = ''
        }
    
        setTimeout(() => {
            toPng(ref.current, { cacheBust: false, backgroundColor: 'white', preferredFontFormat: 'Roboto' }).then((image) => {
                const link = window.document.createElement('a');
                link.style = 'display:none;'
                link.download =  `${name}.png`
                link.href = image;
                link.click()
                link.remove() 
                ref.current.classList.remove('make-image-more-none')
                ref.current.classList.remove('make-image-more-opacity')
                ref.current.classList.remove('make-image-collapse')
                // ref에 패딩 0으로 원복
                ref.current.style.setProperty('padding', 0)
                if (videoElement) {
                    videoElement.innerHTML = videoTmpElement.innerHTML
                    videoTmpElement.remove()
                }

                resolve(1)
            })
        }, 50)
    })
}

const orderComment = 'wdate'
const initOrder = {
    _11: 'point',
    _17: 'point',
    _18: 'point'
}

// 파일 다운로드명
export const getFilename = (props) => {
    const { number, title } = props

    let newStr = String(title).replace(/\r\n/g, "\n").replace(/\n/g, "")

    if (number) newStr = `${number} ${newStr}`

    return newStr.length <= 50 ? newStr : `${newStr.substring(0, 50)}...`
}

const Ppts = new ppts()

const Component = (props) => {
    const { 
        survey_no,
        i, 
        viewMode, 
        print,
        common,
        modules, 
        answers, 
        comments, 
        isComments, 
        option, 
        onUpdateSurveyQuestionSummaryQuestionOpen,
        onUpdateSurveyQuestionSummaryCommentOpen,
        onChangeOpen,
        popoverImageProps 
    } = props

    const { survey_question_no, summary_question_open, summary_comment_open, question, question_type, img_src, number, module_type: __typename } = common
        
    const { formatMessage: f } = useIntl()

    const divRef = useRef(null)

    const filename = getFilename({ number: `Q${number}.`, title: question })

    // 파일 업로드 오픈 클로즈
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false)


    // 코멘트 펼침
    const [collapse, setCollapse] = useState(false)

    const { survey_analysis_users_no } = useSelector(state => state.analysisSummaryTargetUser)
    const { order: orderType } = useSelector(state => state.analysisSummaryOrder)

    // response => 순위형은 point 다른형은 count이다.
    const order = orderType === 'answer' ? 'answer' : (initOrder[__typename] || orderType)

    const handleChangeCollapse = () => setCollapse(prevState => !prevState)

    const handleOpenFileUploadDialog = useCallback(() => {
        setOpenFileUploadDialog(true)
    }, [])

    const handleCloseFileUploadDialog = useCallback(() => {
        setOpenFileUploadDialog(false)
    }, [])

    const changeSummaryQuestionOpen = () => {
        const value = !summary_question_open

        if (summary_question_open) onChangeOpen(survey_question_no, 'summary_comment_open', false)
        onChangeOpen(survey_question_no, 'summary_question_open', value)

        // db  업데이트
        onUpdateSurveyQuestionSummaryQuestionOpen({ survey_question_no, summary_question_open: value})
    }

    const changeSummaryCommentOpen = () => {
        const value = !summary_comment_open
        onChangeOpen(survey_question_no, 'summary_comment_open', value)
        // db  업데이트
        onUpdateSurveyQuestionSummaryCommentOpen({ survey_question_no, summary_comment_open: value})
    }

    const animation = true


    // 코멘트 컴포넌트 타입
    const commentComponentType = {
        _01: 'module',
        _02: 'module',
        _03: 'base',
        _04: 'base',
        _05: 'base',
        _06: 'base',
        _07: 'base',
        _08: 'base',
        _11: 'base',
        _12: 'module',
        _13: 'module',
        _14: 'base',
        _15: 'base',
        _17: 'rank',
        _18: 'rank',
        _19: 'base',
        _21: 'base'
    }

    const { text: sectionText, cols: sectionCols } = getSection({ f, __typename })
    
    

    const handleQuestionButtonPpt = useCallback(async(props) => {
        const { question, img_src, number, modules, answers, __typename, question_type, order, sectionText, option } = props

        return new Promise(async(relove) => {
            if (__typename === '_19') {
                handleOpenFileUploadDialog()
                return relove(1)
            }

            Ppts.init({ 
                f, 
                question, 
                img_src, 
                number, 
                modules, 
                answers, 
                comments: [], 
                __typename, 
                questionType: question_type, 
                order, 
                orderComment, 
                section: sectionText, 
                option 
            })
           
            await Ppts.doOne(filename)
    
            return relove(1)
        })


       
    }, [
        f,
        filename, 
        handleOpenFileUploadDialog
    ])

    const handleQuestionButtonImage = useCallback(async() => {
        return new Promise(async(relove) => {
            if (!divRef) return relove(1)
            if (!divRef.current) return relove(1)
    
            await saveGraphImage({ ref: divRef, name: filename })
    
            return relove(1)
        })
       
    }, [divRef, filename])

    // 관리자 요약페이지 또는 질문공개시 또는 나의결과보기일경우 무적권 보인다.
    let questionVisible = summary_question_open || survey_analysis_users_no

    // 관리자 요약페이지 또는 질문공개시 또는 나의결과보기일경우 무적권 보인다. (코멘트값이 무적권 잇어야한다), 인쇄페이지가 아니여야 한다.
    let commentVisible = (summary_comment_open || survey_analysis_users_no) && isComments && !print

    return (
        <>
        <QuestionPaper index={i}>
            {
                viewMode === 'edit' && (
                    <VisibleLangComponent 
                        __typename={__typename} 
                        summary_question_open={summary_question_open} 
                        summary_comment_open={summary_comment_open} 
                        onChangeQuestion={changeSummaryQuestionOpen} 
                        onChangeComment={changeSummaryCommentOpen}
                    />
                )
            }
            {
                viewMode === 'view' && (
                    <QuestionNumberStyled>
                        <div>
                            <NumberIconComponent number={number} __typename={__typename} />
                        </div>
                        <div>
                            <SurveyCreateAnalysisSummaryQuestionButtonsComponent>
                            {   
                                (__typename === '_09' || __typename === '_10' || __typename === '_20') ? (
                                    <QuestionButtonTextComponent survey_no={survey_no} survey_question_no={survey_question_no} filename={filename} commentLen={props.commentLen} />
                                ): (__typename === '_19') ? (
                                    <QuestionButtonFileComponent viewMode={viewMode} survey_no={survey_no} survey_question_no={survey_question_no} moduleLen={props.moduleLen} filename={filename} />
                                ) : (
                                    <>
                                    <QuestionButtonPptComponent
                                        question={question} 
                                        img_src={img_src} 
                                        number={number}
                                        modules={modules} 
                                        answers={answers} 
                                        __typename={__typename} 
                                        question_type={question_type} 
                                        order={order} 
                                        sectionText={sectionText} 
                                        option={option}
                                        onSave={handleQuestionButtonPpt} 
                                    />
                                    <QuestionButtonImageComponent onSave={handleQuestionButtonImage} />
                                    </>
                                )
                            }
                            </SurveyCreateAnalysisSummaryQuestionButtonsComponent>
                        </div>
                    </QuestionNumberStyled>
                )
            }
            <QuestionBoxStyled>
                <QuestionBoxForMakeImageStyled ref={divRef}>
                {
                    viewMode === 'view' ? (
                        <SummaryQuestion number={number} question={question} img_src={img_src} print={print}/>
                    ) : (
                        <SummaryQuestionReport number={number} question={question} img_src={img_src}/>
                    )
                }
                {
                    (questionVisible && (__typename === '_01' || __typename === '_02' || __typename === '_21')) ? (
                        <>
                        <Section text="" cols=""/>
                        <Bar01Component modules={modules} order={order} animation={animation}/>
                        </>
                    ) : 
                    (questionVisible && (__typename === '_03' || __typename === '_04')) ? (
                        <>
                        <Section text="" cols=""/>
                        <Bar03ColumnComponent modules={modules} order={order} animation={animation}/>
                        <DataItemMoreComponent print={print}>
                            <Section text={sectionText[1]}  cols="" isFirst={true}/>
                            <Bar03AnswerComponent answers={answers} order={order} animation={animation}/>
                        </DataItemMoreComponent>
                        </>
                    ) : (questionVisible && (__typename === '_05')) ? (
                        <>
                        <NpsCalcu rows={modules} isNps={option.nps}/>
                        <Bar05Component modules={modules} animation={animation}/>
                        <NpsLabel isNps={option.nps} left_label={option.left_label} right_label={option.right_label}/>
                        </>
                    ) : (questionVisible && (__typename === '_06')) ? (
                        <>
                        <Section text="" cols=""/>
                        <Bar06ColumnComponent modules={modules} option={option} animation={animation}/>
                        <Section text={sectionText[1]} cols="" isFirst={false}/>
                        <Bar06AnswerComponent answers={answers} animation={animation}/>
                        <NpsLabel isNps={option.nps} left_label={option.left_label} right_label={option.right_label}/>
                        </>
                    ) : (questionVisible && (__typename === '_07' || __typename === '_14')) ? (
                        <>
                        <NpsCalcu rows={modules} isNps={false}/>
                        <Bar07Component modules={modules} animation={animation}/>
                        </>
                    ) : (questionVisible && (__typename === '_08' || __typename === '_15')) ? (
                        <>
                        <Section text="" cols=""/>
                        <Bar08ColumnComponent modules={modules} animation={animation}/>
                        <Section text={sectionText[1]} cols="" isFirst={false}/>
                        <Bar08AnswerComponent answers={answers} animation={animation}/>
                        </>
                    ) : (questionVisible && componentType[__typename] === 'text') ? (
                        <>
                            <Section text=""/>
                            <DataItemCommentHeaderComponent index={0} commentLen={props.commentLen}/>
                            <DataItemCommentsComponent survey_no={survey_no} id={survey_question_no} comments={comments} commentLen={props.commentLen} /> 
                        </>
                    ) : (questionVisible && (__typename === '_11' || __typename === '_17')) ? (
                        <>
                        <Section text="" cols={sectionCols[0]}/>
                        <Bar11Component modules={modules} order={order} animation={animation}/>
                        <DataItemMoreComponent print={print}>
                            <Section text={sectionText[1]} cols="" isFirst={true}/>
                            <Bar11AnswerComponent answers={answers} order={order} animation={animation}/>
                        </DataItemMoreComponent>
                        </>
                    ) :  (questionVisible && (__typename === '_12' || __typename === '_13')) ? (
                        <>
                        <Section text="" cols=""/>
                        <Bar12Component modules={modules} order={order} animation={animation} onOpen={popoverImageProps.on.open}/>
                        </>
                    ) : (questionVisible && __typename === '_18') ? (
                        <>
                        <Section text="" cols={sectionCols[0]}/>
                        <Bar18Component modules={modules} order={order} animation={animation} onOpen={popoverImageProps.on.open}/>
                        <DataItemMoreComponent print={print}>
                            <Section text={sectionText[1]} cols="" isFirst={true}/>
                            <Bar18AnswerComponent answers={answers} order={order} animation={animation}/>
                        </DataItemMoreComponent>
                        </>
                    ) : (questionVisible && componentType[__typename] === 'file') ? (
                        <DataItemFileUploadComponent 
                            survey_no={survey_no}
                            survey_question_no={survey_question_no} 
                            filename={filename}
                            modules={modules}
                            moduleLen={props.moduleLen}
                            viewMode={viewMode}
                        />
                    ) : ''

                }
                </QuestionBoxForMakeImageStyled>
            </QuestionBoxStyled>
        </QuestionPaper>
        {
            commentVisible && (
                <>
                <CommentsButtonStyled collapse={collapse} onChange={handleChangeCollapse}/>
                <CollapseBox open={collapse} print={print}>
                    <QuestionPaper index={0}>
                        {
                                viewMode === 'view' && (
                                <QuestionNumberStyled>
                                    <div></div>
                                    <div>
                                        {
                                            commentComponentType[__typename] === 'module' ? (
                                                <QuestionButtonModuleTextComponent 
                                                    survey_no={survey_no}
                                                    survey_question_no={survey_question_no}
                                                    modules={modules} 
                                                    filename={filename}
                                                />         
                                            ) : commentComponentType[__typename] === 'rank' ? (
                                                <QuestionButtonRankTextComponent 
                                                    survey_no={survey_no}
                                                    survey_question_no={survey_question_no}
                                                    answers={answers} 
                                                    filename={filename}
                                                />         
                                            ) : commentComponentType[__typename] === 'base' && (
                                                <QuestionButtonTextComponent 
                                                    survey_no={survey_no} 
                                                    survey_question_no={survey_question_no} 
                                                    filename={filename}
                                                    commentLen={props.commentLen}
                                                />    
                                            )
                                        }
                                    </div>
                                </QuestionNumberStyled>
                            )
                        }
                        <QuestionBoxStyled>
                            {
                                commentComponentType[__typename] === 'module' ? (
                                    <CommentsModulesComponent 
                                        survey_no={survey_no}
                                        survey_question_no={survey_question_no}
                                        modules={modules} 
                                    />         
                                ) : commentComponentType[__typename] === 'rank' ? (
                                    <DataItemRankCommentsComponent 
                                        survey_no={survey_no}
                                        survey_question_no={survey_question_no}
                                        answers={answers} 
                                    />         
                                ) : commentComponentType[__typename] === 'base' && (
                                    <>
                                    <DataItemCommentHeaderComponent index={0} commentLen={props.commentLen}/>
                                    <DataItemCommentsComponent survey_no={survey_no} id={survey_question_no} comments={comments} commentLen={props.commentLen} />     
                                    </>
                                )
                            }   
                        </QuestionBoxStyled>
                    </QuestionPaper>
                </CollapseBox>
                </>
            )  
        }
        </>
    )
}

export default withSurveyPopoverImage(memo(Component, (prev, next) => {
    return  isEqual(prev.i, next.i) && 
            isEqual(prev.viewMode, next.viewMode) && 
            isEqual(prev.print, next.print) && 
            isEqual(prev.common, next.common) && 
            isEqual(prev.modules, next.modules) && 
            isEqual(prev.answers, next.answers) && 
            isEqual(prev.comments, next.comments) && 
            isEqual(prev.isComments, next.isComments) && 
            isEqual(prev.option, next.option) && 
            isEqual(prev.onUpdateSurveyQuestionSummaryQuestionOpen, next.onUpdateSurveyQuestionSummaryQuestionOpen) && 
            isEqual(prev.onUpdateSurveyQuestionSummaryCommentOpen, next.onUpdateSurveyQuestionSummaryCommentOpen) && 
            isEqual(prev.onShowBackdrop, next.onShowBackdrop) && 
            isEqual(prev.onHideBackdrop, next.onHideBackdrop)
}))